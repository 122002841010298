import React, { useEffect, useState } from "react";
import { Box, Slide, Typography } from "@mui/material";
import { BsArrowUpRight } from "react-icons/bs";
import Carousel from "../../components/ui/Carousel";
import ByteIcon from "./ByteIcon";
import DragDropList from "../../components/dragDropList/index";

type DragAndDropPageProps = {
  carouselSlidesData: any[];
  mappedAgentsData: any;
  handleNextBtnClick: () => void;
  setQuestionsList: any;
  setSelectedAgent: any;
  list: any;
  setList: any;
};

const DragAndDropPage: React.FC<DragAndDropPageProps> = ({
  carouselSlidesData,
  mappedAgentsData,
  handleNextBtnClick,
  list,
  setList
}) => {
  const [category, setCategory] = useState<string>(
    carouselSlidesData[0].content
  );

  const clickNextBtn = () => {
    handleNextBtnClick();
  };

  return (
    <div className="drag-and-drop-page">
      <div className="text-center">
        <ByteIcon size={52} />
        <Typography
          variant="h4"
          sx={{
            display: "inline",
            fontSize: { xs: "24px", sm: "33px" },
            paddingLeft: "10px",
            textAlign: "center",
            width: {xs:"344px", md:"100%"},
            marginTop: { xs: "10px", sm: "0" }
          }}
        >
          Which AI model or agent would you like to use to create impactful features?
        </Typography>
      </div>
      <div className="carousel-container">
        <Carousel slides={carouselSlidesData} setCategory={setCategory} />
      </div>
      <div className="drag-drop-list">
        <DragDropList
          list={setList}
          mappedAgentsData={mappedAgentsData}
          category={category}
        />
      </div>
      <div className="left-size-text">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
        </Box>
      </div>
      {list.length === 4 && (
        <div
          onClick={clickNextBtn}
          className="next-button1"
        >
          Next <BsArrowUpRight />
        </div>
      )}
    </div>
  );
};

export default DragAndDropPage;
