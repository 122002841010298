// @ts-nocheck
import React from "react";
import { useDnD } from "./DnDContext";
import { Background } from "@xyflow/react";
import { Button, Box } from "@mui/material";
import PresentRoundedIcon from "@mui/icons-material/OndemandVideo";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import ExpandMore from "./ExpandMore";
import SustainabilityPresentation from "../ui/SustainabilityPresentation";

export default ({
  cardData,
  setCardData,
  title,
  isButtonVisible = false,
  setNodeColor,
  setExpanded,
  expanded,
}) => {
  const [_, setType] = useDnD();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const onDragStart = (event, nodeType, color, sustainable) => {
    setType(nodeType);
    event.dataTransfer.effectAllowed = "move";
    if(sustainable){
      event.dataTransfer.setData('sustainable', sustainable);
    }
    setNodeColor(color);
  };

  const onAiToolCreateBtnClick = () => {
    const newNode = {
      title: "Voice To Text",
      desc: "Neque porro quisquam est quiatese dolorem ipsum quia dolor sitr amet",
      appName: "Voice to Text",
      color: "#FFF2EC",
      icon: <PresentRoundedIcon />,
      sustainable: '34%',
    };
    const newAiToolJson = [...cardData, newNode];
    setCardData(newAiToolJson);
  };

  return (
    <div className="sidebar">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="toolbox-title">{title}</div>
        <div className="toolbox-description">
          Drag this onto your concept to enhance what AI can do for your
          application
        </div>
        <Box
          ml={8}
          mt={1}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {isButtonVisible && (
            <Button
              variant="contained"
              size="small"
              onClick={onAiToolCreateBtnClick}
              sx={{ background: "#377610" }}
            >
              New App
            </Button>
          )}
        </Box>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <aside>
          {cardData.map((item, index) => (
            <div
              className="dndnode"
              onDragStart={(event) =>
                onDragStart(event, item.appName, item.color, item?.sustainable)
              }
              draggable
              style={{ backgroundColor: item.color, borderColor: item.color }}
            >
              <div style={{display:'flex', gap:'90px'}}>
              <div className="icon">{item.icon}</div>
              <div style={{position:'relative', bottom:'7px', left:'4px'}}>
              <SustainabilityPresentation sustainabilityPercentage={item?.sustainable}/>
              </div>
              </div>
              <div className="title">{item.title}</div>
              <div className="description">{item.desc}</div>
            </div>
          ))}
        </aside>
      </Collapse>
    </div>
  );
};
