import React, { useState, useEffect } from "react";
import { BsArrowUpRight } from "react-icons/bs";
import "./index.css";
import { Typography, Box, Button } from "@mui/material";
import Logo from "../../assets/logo.svg";
import Countdown from "./Countdown";
import Questionnaire from "./Questionnaire";
import CallToActions from "./CallToActions";
import ByteSizedAiIntro from "./ByteSizedAiIntro";
import ByteIcon from "./ByteIcon";
import TypeWriter from "./TypeWriter";
import { useLocation, useNavigate } from "react-router-dom";
import DragAndDropPage from "./DragAndDrop";
import axiosConfig from "../../services/axiosConfig";
import { BiMessageSquareError } from "react-icons/bi";
import { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";
import { getUserOnboardingStage } from "../../services/userAgentService";
import { getSessionId, setSessionId } from "../../utils/sessionUtils";
import BackButton from "./BackButton";
import { getIconFromRegistry } from "./Icons";
import localStorageUtil from "../../utils/localStorageUtil";

// Generate the session ID on the frontend
const generateSessionId = () => {
  return uuidv4();
};

interface CarouselSlideData {
  content: string;
}

type Agent = {
  agent_name: string;
  tooltip: string;
  category: string;
  use_cases: string[];
  position: number;
  icon_link: string;
  description: string;
  _id: string;
};

type CategoryMap = {
  [category: string]: {
    id: string;
    agent_name: string;
    category: string;
    description: string;
    icon_link: string;
    position: number;
    tooltip: string;
    use_cases: string[];
    icon: JSX.Element | null;
  }[];
};

interface SelectedField {
  agent_id: string;
  selected_choices: string;
}

interface Payload {
  session_id: string;
  email_id?: string;
  agent_ids: string[];
}

type UserAgentUpdateResponse = {
  session_id: string;
  user_id: string | null;
  email_id: string | null;
  agent_ids: string[];
  selected_fields: SelectedField[];
  _id: string;
};

export enum AppStage {
  STAGE_ONE = "first_page",
  STAGE_TWO = "agent_confirmation_page",
  STAGE_THREE = "agent_option_page",
  STAGE_FOUR = "eoi_intro_page",
}

const impressiveScreen = [
  <div key="5" className="eoi-page">
    <Box sx={{ display: "flex", alignItems: "center", position: "relative" }}>
      <Box sx={{ position: "absolute" }}>
        <ByteIcon size={72} />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", paddingLeft: "80px" }}>
        <Typography
          variant="subtitle1"
          className="typewriter"
          sx={{
            display: "inline-block",
            fontSize: {
              xs: "24px",
              sm: "30px",
              md: "52px",
            },
          }}
        >
          Impressive choices !
        </Typography>
      </Box>
    </Box>
  </div>,
];

const meetByteScreen = [
  <div key="5" className="eoi-page">
    <Box sx={{ display: "flex", alignItems: "center", position: "relative" }}>
      <Box sx={{ position: "absolute" }}>
        <ByteIcon size={72} />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", paddingLeft: "80px" }}>
        <Typography
          variant="subtitle1"
          className="typewriter"
          sx={{
            display: "inline-block",
            fontSize: {
              xs: "24px",
              sm: "30px",
              md: "52px",
            },
            width: "800px",
          }}
        >
          What if bringing AI into your product stack felt as smooth and easy as
          that last little thing you just did?
        </Typography>
      </Box>
    </Box>
  </div>,
];

const ExpressionOfInterest: React.FC = () => {
  const [currentDiv, setCurrentDiv] = useState<number>(0);
  const [nextClick, setNextClick] = useState<boolean>(false);
  const [list, setList] = useState<any[]>([]);
  const [showCallToAction, setCallToAction] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [questionsList, setQuestionsList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [selectedAgents, setSelectedAgent] = useState([]);
  const [userAgentSelectionId, setUserAgentSelectionId] = useState("");
  const [sessionId, setSessionIdState] = useState<string>(() => {
    const storedSessionId = getSessionId();
    if (storedSessionId) {
      return storedSessionId;
    }
    const newSessionId = generateSessionId();
    setSessionId(newSessionId);
    return newSessionId;
  });
  const [stage, setStage] = useState<string>("");
  const [currentStage, setCurrentStage] = useState<any>(AppStage.STAGE_ONE);

  const stages = [
    AppStage.STAGE_ONE,
    AppStage.STAGE_TWO,
    AppStage.STAGE_THREE,
    AppStage.STAGE_FOUR,
  ];

  const handleBackButtonClick = () => {
    const currentStageIndex = stages.indexOf(currentStage);
    if (currentStageIndex > 0) {
      setCurrentStage(stages[currentStageIndex - 1]);
      setUiScrees((prevScreens: any) => {
        const updatedScreens = prevScreens.slice(0, -1);
        setCurrentDiv((prev) => {
          if (prev >0) {
            return prev - 1;
          }
          return prev;
        });
        return updatedScreens;
      });
    }
  };

  const isBackButtonVisible = currentStage !== AppStage.STAGE_ONE;

  useEffect(() => {
    const getAgentsData = async () => {
      const payload = {
        offset: 0,
        limit: 50,
        sortBy: "position",
        sortOrder: 1,
        filters: "learning_based",
      };
      try {
        const result = await axiosConfig.post("/v1/admin/agents/get", payload);
        setData(result);
      } catch (err: any) {
        setError(err?.message);
      }
    };

    const getUserOnboardingStages = async () => {
      const payload = {
        session_id: sessionId,
      };
      try {
        const result = await getUserOnboardingStage(payload);
        if ((result as any)?.stage !== AppStage.STAGE_ONE) {
          setCurrentDiv(4);
        }
        setCurrentStage((result as any).stage);
      } catch (err: any) {
        setError(err?.message);
      }
    };

    getAgentsData();
    getUserOnboardingStages();
  }, []);

  const mapItemsToCategories = (items: Agent[]): CategoryMap => {
    return items?.reduce((acc, item) => {
      if (!acc[item.category]) {
        acc[item.category] = [];
      }
      acc[item.category].push({
        id: item._id,
        agent_name: item.agent_name,
        category: item.category,
        description: item.description,
        icon_link: item.icon_link,
        position: item.position,
        tooltip: item.tooltip,
        use_cases: item.use_cases,
        icon:  getIconFromRegistry(item.icon_link, 18)
      });
      return acc;
    }, {} as CategoryMap);
  };

  const mappedAgentsData = mapItemsToCategories(data?.items);

  const mapAgentDataToCarouselSlides = (
    agentData: CategoryMap
  ): CarouselSlideData[] => {
    return (
      agentData &&
      Object.keys(agentData).map((category) => ({
        content: category,
      }))
    );
  };

  const carouselSlidesData = mapAgentDataToCarouselSlides(mappedAgentsData);

  const divs: JSX.Element[] = [
    <div key="1" className="eoi-page">
      <div className="logo">
        <Box
          component="img"
          src={Logo}
          alt="Description of the image"
          sx={{
            width: "100%",
            maxWidth: { xs: "300px", sm: "450px", md: "625px" },
            height: "auto",
          }}
        />
      </div>
      <div className="">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="subtitle1"
            sx={{
              display: "inline-block",
              fontSize: {
                xs: "18px",
                sm: "30px",
                md: "38px",
              },
              paddingLeft: {
                xs: "55px",
                sm: "30px",
                md: "82px",
              },
            }}
          >
            Powered by BYTE
          </Typography>
          <Box sx={{ paddingLeft: "5px" }}>
            <ByteIcon size={48} />
          </Box>
        </Box>
      </div>
    </div>,
    <div key="2"></div>,
    <div key="3" className="eoi-page">
      <Countdown />
    </div>,
    <div key="4" className="eoi-page">
      <Box sx={{ display: "flex", alignItems: "center", position: "relative" }}>
        <Box sx={{ position: "absolute" }}>
          <ByteIcon size={72} />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: { xs: "70px", sm: "80px" },
            fontSize: { xs: "14px", sm: "18px" },
          }}
        >
          <Typography
            variant="subtitle1"
            className="typewriter"
            sx={{
              display: "inline-block",
              fontSize: {
                xs: "16px",
                sm: "30px",
                md: "33px",
              },
            }}
          >
            Drag and drop desired features in the box
          </Typography>
        </Box>
      </Box>
    </div>,
  ];

  const [uiScreens, setUiScrees] = useState<any>(divs);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDiv((prev) => {
        if (prev <= uiScreens.length - 1) {
          return prev + 1;
        } else {
          clearInterval(timer);
          return prev;
        }
      });
    }, 4000);
    return () => clearInterval(timer);
  }, [uiScreens.length]);

  useEffect(() => {
    const mappedOptionsList = list.map((item) => {
      return {
        title: item?.agent_name,
        options: item?.use_cases,
        agent_id: item?.id,
        tooltipText: item?.tooltip,
        icon:  getIconFromRegistry(item.icon_link, 18)
      };
    });

    const selectedAgentsIds = list.map((item) => item.id);
    setQuestionsList(mappedOptionsList as any);
    setSelectedAgent(selectedAgentsIds as any);
  }, [list]);

  const updateUserAgents = async (
    payload: Payload
  ): Promise<AxiosResponse<any>> => {
    try {
      const response = await axiosConfig.post<any>("/v1/user-agents", payload);
      return response; 
    } catch (error) {
      console.error("Error updating user agents:", error);
      throw error; 
    }
  };

  const handleNextBtnClick = async () => {
    setNextClick(true);
    setUiScrees((prev: any) => [...prev, ...impressiveScreen]);

    const payload = {
      session_id: sessionId,
      agent_ids: selectedAgents,
    };

    setLoading(true);
    setError(null);
    setCurrentStage(AppStage.STAGE_TWO);

    localStorageUtil.setItem('survey', JSON.stringify(questionsList));

    try {
      const response = await updateUserAgents(payload);
      setUserAgentSelectionId((response as any)?._id);
      console.log(response?.data);
      setSuccess(true);
    } catch (error) {
      setError("Failed to update user agents");
    } finally {
      setLoading(false);
    }
  };

  const handleGoBtnClick = () => {
    setUiScrees((prev: any) => [...prev, ...meetByteScreen]);
    setCallToAction(false);
    setCurrentStage(AppStage.STAGE_FOUR);
  };

  let showForm = currentDiv === uiScreens.length;

  const sentences = [
    <Typography
      variant="subtitle1"
      className="typewriter"
      sx={{
        display: "inline-block",
        fontSize: {
          xs: "16px",
          sm: "30px",
          md: "33px",
        },
      }}
    >
      Let's Get Started
    </Typography>,
  ];

  console.log({ currentStage, currentDiv, uiScreens }, uiScreens.length);
  console.log(
    "currentStage === AppStage.STAGE_TWO",
    currentStage === AppStage.STAGE_TWO,
    showForm
  );

  console.log("uiScreens[currentDiv]", uiScreens[currentDiv]);

  if (currentDiv == 1 && currentStage === AppStage.STAGE_ONE && !showForm) {
    return <TypeWriter sentences={sentences} />;
  }

  return (
    <div>
      {!showForm && uiScreens[currentDiv]}
      {showForm && (
        <div className="eoi-page">
          {currentStage !== AppStage.STAGE_FOUR && (
            <BackButton onClick={handleBackButtonClick} isVisible={isBackButtonVisible} />
          )}
          {currentStage === AppStage.STAGE_ONE && (
            <DragAndDropPage
              carouselSlidesData={carouselSlidesData}
              handleNextBtnClick={handleNextBtnClick}
              mappedAgentsData={mappedAgentsData}
              setQuestionsList={setQuestionsList}
              setSelectedAgent={setSelectedAgent}
              setList={setList}
              list={list}
            />
          )}
          {currentStage === AppStage.STAGE_TWO && (
            <Questionnaire
              list={questionsList}
              setCallToAction={setCallToAction}
              selectedAgents={selectedAgents}
              sessionId={sessionId}
              userAgentSelectionId={userAgentSelectionId}
              setCurrentStage={setCurrentStage}
            />
          )}
          {currentStage === AppStage.STAGE_THREE && (
            <CallToActions
              handleGoBtnClick={handleGoBtnClick}
              sessionId={sessionId}
            />
          )}
          {currentStage === AppStage.STAGE_FOUR && <ByteSizedAiIntro />}
        </div>
      )}
    </div>
  );
};

export default ExpressionOfInterest;
