const localStorageUtil = {
    setItem: <T>(key: string, value: T): void => {
        try {
            const data = typeof value === 'object' ? JSON.stringify(value) : value;
            localStorage.setItem(key, data as any);
        } catch (e) {
            console.error('Error setting data in localStorage:', e);
        }
    },

    getItem: <T>(key: string): T | null => {
        try {
            const data = localStorage.getItem(key);
            return data ? JSON.parse(data) : null;
        } catch (e) {
            console.error('Error getting data from localStorage:', e);
            return null;
        }
    },

    removeItem: (key: string): void => {
        try {
            localStorage.removeItem(key);
        } catch (e) {
            console.error('Error removing data from localStorage:', e);
        }
    },

    clear: (): void => {
        try {
            localStorage.clear();
        } catch (e) {
            console.error('Error clearing localStorage:', e);
        }
    },

    contains: (key: string): boolean => {
        return localStorage.getItem(key) !== null;
    }
};

export default localStorageUtil;
