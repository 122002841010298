// @ts-nocheck
import React, { useEffect, useState } from "react";
import Column from "./Column";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { styled } from "@mui/material";
import DropBucket from "./DropBucket";
import { Typography } from "@mui/material";

const StyledColumns = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  margin: "0 auto",
  width: "56.25rem",
  
  [theme.breakpoints.down("lg")]: {
    width: "100%", 
    // padding: "0 16px",
  },

  [theme.breakpoints.down("md")]: {
    width: "100%",
    // padding: "0 8px",
  },

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    width: "100%",
    padding: "0 8px",
  },
}));

const DragDropList = ({ list, mappedAgentsData, category }) => {
  const initialColumns = {
    todo: {
      id: "todo",
      list: mappedAgentsData[category],
    },
    doing: {
      id: "doing",
      list: [],
    },
  };
  const [columns, setColumns] = useState(initialColumns);
  const [error, setError] = useState<string | null>(null);

  const onDragEnd = ({ source, destination }: DropResult) => {
        // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null;
    // Set start and end variables
    const start = columns[source.droppableId];
    const end = columns[destination.droppableId];

        // If start is the same as end, we're in the same column
    if (start === end) {
        // Move the item within the list
      const newList = start.list.filter(
        (_: any, idx: number) => idx !== source.index
      );
      newList.splice(destination.index, 0, start.list[source.index]);

      const newCol = {
        id: start.id,
        list: newList,
      };

      setColumns((state) => ({ ...state, [newCol.id]: newCol }));
      return null;
    } else {
      const newStartList = start.list.filter(
        (_: any, idx: number) => idx !== source.index
      );

      const newStartCol = {
        id: start.id,
        list: newStartList,
      };

      const newEndList = [...end.list];

      if (destination.droppableId === "doing" && newEndList.length >= 4) {
        setError("You can only have up to 4 agents");
        return null;
      } else {
        setError(null);
      }

      newEndList.splice(destination.index, 0, start.list[source.index]);

      const newEndCol = {
        id: end.id,
        list: newEndList,
      };

      setColumns((state) => ({
        ...state,
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol,
      }));
      return null;
    }
  };

  useEffect(() => {
    list([...columns.doing.list]);
  }, [columns]);

  useEffect(() => {
    setColumns((prevState) => ({
      ...prevState,
      todo: {
        ...prevState.todo,
        list: mappedAgentsData[category],
      },
    }));
  }, [category]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StyledColumns>
        <Column col={Object.values(columns)[0]} />
        <DropBucket col={Object.values(columns)[1]} />
        {error && (
          <Typography
            variant="subtitle1"
            sx={{
              display: "inline-block",
              paddingTop: "10px",
              fontSize: {
                xs: "10px",
                sm: "12px",
                md: "16px",
              },
              color: "error.main",
            }}
          >
            {error}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          sx={{
            display: "inline-block",
            paddingTop: "10px",
            fontSize: {
              xs: "10px",  
              sm: "12px", 
              md: "16px", 
            },
            textAlign: "center",
            marginTop: "10px",
            width:{xs:"300px", md:'100%'}
          }}
        >
          Feel free to pick up to 4 models or agents — just drag any out of the box if you change your mind.
        </Typography>
      </StyledColumns>
    </DragDropContext>
  );
}

export default DragDropList;
