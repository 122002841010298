import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';

const baseURL = 'https://api-prod.bytesized.com.au';

const api: AxiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

type ApiResponse<T = any> = AxiosResponse<T>;

const request = async <T>(method: 'get' | 'post' | 'put' | 'delete', url: string, data?: any): Promise<T> => {
  try {
    const response: ApiResponse<T> = await api({
      method,
      url,
      data,
    });
    return response.data;  // Return the actual data from the response
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('API Error:', error.response?.data || error.message);
    } else {
      console.error('Unknown Error:', error);
    }
    throw error;
  }
};

// GET method
const get = async <T>(url: string): Promise<T> => {
  return request<T>('get', url);
};

// POST method
const post = async <T>(url: string, data: any): Promise<T> => {
  return request<T>('post', url, data);
};

// PUT method
const put = async <T>(url: string, data: any): Promise<T> => {
  return request<T>('put', url, data);
};

// DELETE method
const del = async <T>(url: string): Promise<T> => {
  return request<T>('delete', url);
};

export default {
  get,
  post,
  put,
  delete: del,
};
