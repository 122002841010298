import React, { useState } from 'react';
import { DataGrid, GridRowSelectionModel } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const initialRows = [
  { id: 1, input: 'Input 1', output: 'Output 1' },
  { id: 2, input: 'Input 2', output: 'Output 2' },
  { id: 3, input: 'Input 3', output: 'Output 3' },
  { id: 4, input: 'Input 4', output: 'Output 4' },
  { id: 5, input: 'Input 5', output: 'Output 5' },
  { id: 6, input: 'Input 6', output: 'Output 6' },
  { id: 7, input: 'Input 7', output: 'Output 7' },
  { id: 8, input: 'Input 8', output: 'Output 8' },
  { id: 9, input: 'Input 9', output: 'Output 9' },
];

interface Props {
  isExpanded: boolean,
  setIsExpanded: (arg:boolean)=>boolean
}

export default function EditableTable({isExpanded, setIsExpanded}: Props) {
  const [rows, setRows] = useState(initialRows);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);

  const handleRowUpdate = (newRow:any) => {
    const updatedRows = rows.map((row) => (row.id === newRow.id ? newRow : row));
    setRows(updatedRows);
    return newRow; // return the updated row
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  

  return (
    <div style={{ position: 'relative', width: '100%', height: isExpanded ? '74vh' : '30vh', zIndex:10, fontSize:'16px' }}>
      <IconButton
        onClick={toggleExpand}
        sx={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}
      >
        {isExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </IconButton>
      <Paper sx={{ width: '100%', height: '100%' }}>
        <DataGrid
          rows={rows}
          columns={[
            { field: 'input', headerName: 'Input', width: 730, editable: true },
            { field: 'output', headerName: 'Output', width: 730, editable: true },
          ]}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={(newSelection:GridRowSelectionModel) => {
            setSelectionModel(newSelection);
          }}
          onCellEditStart={() => {
            setSelectionModel([]);
          }}
          sx={{
            height: '100%',
            '& .MuiDataGrid-cell': {
              border: '1px solid rgba(224, 224, 224, 1)', 
            },
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#b1b1b7',
              border: '1px solid #b1b1b7',
            },
          }}
          processRowUpdate={handleRowUpdate}
        />
      </Paper>
    </div>
  );
}
