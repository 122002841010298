import * as React from "react";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Copyright from "./ui/Copyright";
import DnDFlowCanvas from "./reactFlow/DnDFlowCanvas";
import { useLocation } from "react-router-dom";
import CardDashBoard from "../pages/CardDashBoard";
import HorizontalNonLinearStepper from "./stepper/HorizontalNonLinearStepper";

export default function MainGrid({ yourToolsJson, setYourTools, aiToolsJson }: any) {
  const location = useLocation();
  const { pathname } = location;
  console.log("pathname", pathname, location)
  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      <Grid
        container
        spacing={2}
        columns={8}
        sx={{ mb: (theme) => theme.spacing(2), flexDirection:'column'}}
      >
        {pathname === "/Conceptualize" && (
          <HorizontalNonLinearStepper aiToolsJson={aiToolsJson} yourToolsJson={yourToolsJson} setYourTools={setYourTools}></HorizontalNonLinearStepper>
        )}
        {(pathname === "/Dashboard" || pathname === "/") && (
          <CardDashBoard aiToolsJson={aiToolsJson} ></CardDashBoard>
        )}
      </Grid>
      <Copyright className="copyright" />
    </Box>
  );
}
