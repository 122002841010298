import React from "react";
import { Box, Typography } from "@mui/material";
import ByteIcon from "./ByteIcon";
import Logo from "../../assets/logo.svg";

const ByteSizedAiIntro: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: { xs: "10px", sm: "20px", md: "0px" },
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontSize: { xs: "28px", sm: "24px", md: "30px" },
          paddingLeft: { xs: "5px", sm: "8px", md: "10px" },
          // marginTop: 0,
          position: "absolute",
          top: { xs: "120px", sm: "105px" },
          width: { md: "55%", xs:'90%' },
           textAlign:'center'
        }}
      >
        What if {" "}
        <span style={{ textDecoration: "line-through", color: "gray"}}>
           bringing AI into your product stack felt as smooth and easy as that
          last little thing you just did?
        </span>
      </Typography>
      <Box
        component="img"
        src={Logo}
        alt="Logo"
        sx={{
          width: { xs: "350px", sm: "200px", md: "250px" },
          height: { xs: "85px", sm: "55px", md: "55px" },
          top: { xs: "10px", sm: "35px" },
        }}
        pt={"150px"}
        justifyContent={"center"}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
        pt={"100px"}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: { xs: "28px", sm: "28px", md: "30px" },
            paddingLeft: { xs: "5px", sm: "8px", md: "10px" },
            marginTop: 0,
          }}
        >
          See you soon!
        </Typography>
      </Box>
    </Box>
  );
};

export default ByteSizedAiIntro;
