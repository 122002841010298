import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import ByteIcon from './ByteIcon';
import Logo from '../../assets/logo.svg';
import './TypeWriter.scss';

interface TypeWriterProps {
  sentences: any;
}

const TypeWriter: React.FC<TypeWriterProps> = ({ sentences }) => {
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0); 
  const [key, setKey] = useState<number>(0); 

  useEffect(() => {
    const sentenceChangeInterval = setInterval(() => {
      setCurrentSentenceIndex((prevIndex) => (prevIndex + 1));
    }, 4000);

    return () => clearInterval(sentenceChangeInterval);
  }, [sentences.length]);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [currentSentenceIndex]);

  return (
    <div className="eoi-page">
      {/* <Box
        component="img"
        src={Logo}
        alt="Logo"
        sx={{
          width: { xs: '150px', sm: '200px', md:"250px" },
          height: { xs: '35px', sm: '45px', md:'55px' },
          position: 'absolute',
          top: { xs: '20px', sm: '35px' },
        }}
      /> */}
      <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
        <Box sx={{ position: 'absolute' }}>
          <ByteIcon size={72} />
        </Box>
        <Box key={key} sx={{ display: 'flex', alignItems: 'center', paddingLeft: { xs: '70px', sm: '80px' }, fontSize: { xs: '14px', sm: '18px' } }}>
            {sentences[currentSentenceIndex]}
        </Box>
      </Box>
    </div>
  );
};

export default TypeWriter;
