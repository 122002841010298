import { Tooltip } from "@mui/material";
import { Chip, styled, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import React from "react";
import { Draggable } from "react-beautiful-dnd";

interface ItemProps {
  text: string;
  index: number;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  id: string;
  tooltipText: string;
}

const StyledItem = styled("div")(({ theme }) => ({
  backgroundColor: "#eee",
  borderRadius: theme.shape.borderRadius,
  padding: "4px 8px",
  transition: "background-color .3s ease",
  marginTop: theme.spacing(1), 

  "&:hover": {
    backgroundColor: "#fff",
  },
}));

const DraggableList: React.FC<ItemProps> = ({ text, index, icon, id, tooltipText }) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided: any) => (
        <Tooltip
          title={tooltipText}
          sx={{
            "& .MuiTooltip-tooltip": {
              color: "black",
              padding: "300px 60px !important",
            },
          }}
          arrow
        >
          <Chip
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            label={text}
            icon={icon}
            variant="outlined"
            sx={{
              margin: "8px",
              cursor: "grab",
              color: "white",
              background: "#7bff0033",
              border: "2px solid, #7bff004a",
              padding: "20px 0px",
              fontSize:{xs:'16px'},
              // width:{xs:'300px', md:"350px"}
            }}
          />
        </Tooltip>
      )}
    </Draggable>
  );
};

export default DraggableList;
